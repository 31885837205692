import React from 'react';

import { Box, Typography, alpha, styled } from "@mui/material";

const StyledContainer = styled(Box, {
  shouldForwardProp: prop => !['heroImage', 'isVenturify', 'noHero'].some(i => prop === i),
})<{ heroImage?: string, isVenturify?: boolean, noHero?: boolean }>(({ heroImage, isVenturify, noHero, theme }) => ({
  alignItems: "center",
  backgroundImage: (heroImage && !noHero) ? `url(${heroImage})` : 'none',
  backgroundPosition: "0 98%",
  backgroundSize: isVenturify ? "110%" : "cover",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "> div": {
    alignItems: "center",
    animation: isVenturify ? "bgColor 10s infinite linear" : "none",
    backgroundColor: isVenturify ? alpha(theme.palette?.secondary.main, 0.3) : "transparent",
    display: "flex",
    marginTop: "8px",
  },
  "h3, h2": {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    marginTop: "-8px",
    textShadow: `1px 1px 2px ${theme.palette?.secondary.main}`,
  },
}));

interface HeroContainerProps {
  companyName: string;
  heroImage?: string;
  noHero?: boolean;
};

const HeroContainer = ({
  companyName,
  heroImage,
  noHero,
}: HeroContainerProps) => (
  <StyledContainer isVenturify={companyName === 'Venturify'} heroImage={heroImage} sx={{ height: noHero ? "100%" : { xs: "calc(100vw - 48px)", sm: companyName === 'venturify' ? "400px" : "484px" }, maxHeight: companyName === 'venturify' ? "40vh" : "none", pb: noHero ? "12px" : 0 }}>
    <Box sx={{ ml: { xs: noHero ? "-28px" : "-60px", sm: noHero ? 0 : "-72px" }, pl: { xs: 0, sm: noHero ? "8px" : 0 }, pt: { xs: noHero ? "6px" : 0, sm: "6px" }, height: { xs: noHero ? "calc(50%)" : "5.5vh", sm: noHero ? "28px" : "52px" }, width: { xs: noHero ? "calc(100% - 30px)" : "59vw", sm: noHero ? "206px" : "264px" } }}>
      <Typography color="common.white" variant="h2" sx={{ fontSize: { xs: noHero ? "32px" : "12.5vw", sm: noHero ? "32px" : "56px"}, ml: { xs: 0, sm: "-2px" } }}>{companyName}</Typography>
      {companyName === 'Venturify' && <Typography color="common.white" variant="h3" mt={2} sx={{ fontSize: { xs: noHero ? "18px" : "28px", sm: noHero ? "20px" :"36px"} }}>.xyz</Typography>}
    </Box>
  </StyledContainer>
);

export default HeroContainer;