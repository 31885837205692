import { askURL } from "../urls";

export const askChatGPT = async (prompt, temperature = 0, max_tokens = 1000) => {
  const token = localStorage.getItem("jwtToken");
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(askURL, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      prompt,
      temperature,
      max_tokens,
    }),
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));
}

export const getGeneratedImage = async (size, prompt) => {
  const token = localStorage.getItem("jwtToken");
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(askURL, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      prompt,
      size,
    }),
    redirect: 'follow'
  })
  .then(response => response.json())
  .catch(error => console.log('error', error));
};
