import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { Box, Button, TextField, Typography} from "@mui/material";
import CasinoIcon from '@mui/icons-material/Casino';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "react-query";

import { askChatGPT } from "../utils/queries/openai";
import { CompanyData, PreviewData, UserData } from "../utils/types";
import { fetchNewLogo, fetchNewName, fetchNewTagline, postCompany } from "../utils/queries/companies";

interface CreateProps {
  handleOnSuccess: (newId: string) => void;
  hasCompany: boolean;
  showTitle?: boolean;
  isLoadingUserData: boolean;
  isFetchingJWT: boolean;
  isGenerating: boolean;
  userData?: UserData;
  onSubmit?: (newVal?: string) => void;
  previewData?: PreviewData;
  setPreviewData: Dispatch<SetStateAction<PreviewData>>;
};

export const Create = ({ handleOnSuccess, hasCompany, showTitle, isLoadingUserData, isFetchingJWT, isGenerating, onSubmit, setPreviewData, userData }: CreateProps) => {
  const [userPrompt, setUserPrompt] = useState("");

  const limitMet = useMemo(() => (!userData?.grantedProducts.some(product => product === 'venturify') && hasCompany), [hasCompany, userData?.grantedProducts])

  const { mutate: createCompany, isLoading } = useMutation((companyData: PreviewData) => postCompany(companyData), {
    onSuccess: (res: { company: CompanyData }) => {
      handleOnSuccess(res.company._id);
      setUserPrompt("");
    }
  });

  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(isLoading || isGenerating || isFetchingJWT || isLoadingUserData || limitMet);

  const generateCompany = async (defaultPrompt?: string) => {
    if (defaultPrompt?.length) setUserPrompt("")

    onSubmit && onSubmit(undefined);
    setPreviewData({ id: "test_id" });

    const prompt = defaultPrompt?.length ? defaultPrompt : userPrompt;

    const name = await fetchNewName(prompt);

    setPreviewData(prevPreviewData => ({ ...prevPreviewData, name }));
    
    const descriptionRaw = await askChatGPT(`${prompt}. Description of the project that is named ${name}| [Description]`);
    const description = descriptionRaw.split("\n").join(" ").trim()

    setPreviewData(prevPreviewData => ({ ...prevPreviewData, description }));

    const tagline = await fetchNewTagline({ name, description });

    setPreviewData(prevPreviewData => ({ ...prevPreviewData, tagline }));

    const slugRaw = await askChatGPT(`${prompt}. A short, hyphenated or one-word url slug representing ${name}'s subdomain| [slug]`, 1, 8);
    const slug = slugRaw.split("\n").join("").split("").filter((c: string) => {
      return (c >= "0" && c <= "9") || (c >= "a" && c <= "z") || (c >= "A" && c <= "Z") || c === "-"
    }).join("").trim();

    setPreviewData(prevPreviewData => ({ ...prevPreviewData, slug }));

    const logo = await fetchNewLogo({ name, description });

    setPreviewData(prevPreviewData => ({ ...prevPreviewData, logo: [logo] }));

    createCompany({
      name,
      description,
      logo,
      prompt,
      slug,
      tagline,
    });
  };

  useEffect(() => {
    if (!shouldDisableSubmit && (isLoading || isGenerating || isFetchingJWT || isLoadingUserData || limitMet)) setShouldDisableSubmit(true)
    else if (shouldDisableSubmit && !(isLoading || isGenerating || isFetchingJWT || isLoadingUserData || limitMet)) setShouldDisableSubmit(false);
  }, [isLoading, isGenerating, isFetchingJWT, isLoadingUserData, shouldDisableSubmit, limitMet]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ transition: "600ms all" }}>
      <Typography variant="h2" color="text.primary" sx={{ fontSize: { xs: "48px", sm: "60px" }, mb: { xs: 4, sm: 2 }, height: showTitle ? { xs: "148px", sm: "100px" } : 0, opacity: showTitle ? 1 : 0, transition: "300ms all" }}>Create a company, event, or project</Typography>
        
      <Box display="flex" alignItems="center" gap={1} mb={2} width="100%" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
        <TextField disabled={isLoading || isGenerating || isFetchingJWT || !userData || limitMet} fullWidth multiline placeholder="Start with an idea" value={userPrompt} onChange={(event) => setUserPrompt(event.target.value)} />
        <Box display="flex" alignItems="center" gap={1}>
          <LoadingButton loading={isLoading} variant="contained" disabled={!userPrompt.length || isGenerating || isFetchingJWT || !userData || limitMet} onClick={() => generateCompany()}>Go</LoadingButton>
          <Button variant="contained" disabled={shouldDisableSubmit || !userData} aria-label="Roll the dice" onClick={() => generateCompany("an interesting project about something specific")}>
            <CasinoIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  )
};

export default Create;