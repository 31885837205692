import { Task } from "../types";
import { tasksURL } from "../urls";

export const fetchTasks = (companyId: string, token: string): Promise<Task[]> => fetch(`${tasksURL}/${companyId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(res => res.json()).catch(e => console.log(`Error fetching tasks: ${e}`));

  export const clearAllTasks = (companyId: string, token: string) => {
    return fetch(`${tasksURL}/${companyId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then(res => res.json());
  };
  
  // Function to create a new task
  export const createTask = (taskData: {}, token: string) => fetch(tasksURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(taskData)
  }).then(res => res.json());