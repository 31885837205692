import { usersURL } from "../urls";

export const fetchJWTToken = async (userData) => {
  const response = await fetch(usersURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  });

  if (!response.ok) {
    throw new Error('Failed to log in');
  }

  const result = await response.json();

  return result.token;
};

export const getUser = async (token) => {
  try {
    const response = await fetch(usersURL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  } catch (error) {
    return error;
  }
};