import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import DashboardView from "./views/Dashboard";
import ErrorBoundary from './components/ErrorBoundary';
import AboutView from './views/About';
import LaunchPage from './views/LaunchPage';
import PrivacyView from './views/Privacy';

import { baseURL } from './utils/urls';

import "./utils/fonts/Montserrat.ttf";
import "./utils/fonts/PTSerif.ttf";
import RolesView from './views/Roles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        const token = localStorage.getItem("jwtToken");
        const headers: { ['Content-Type']: string, Authorization?: string } = {
          'Content-Type': 'application/json'
        };

        if (token) headers.Authorization = `Bearer ${token}`;

        const data = await fetch(`${baseURL}/${queryKey[0]}${queryKey[1] ? `/${queryKey[1]}` : ""}`, {
          headers,
        })
        .then(async res => res.json());

        if (data.message === "jwt expired") {
          localStorage.removeItem("jwtToken");
        } else return data;
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardView />,
  },
  {
    path: "/about",
    element: <AboutView />,
  },
  {
    path: "/privacy",
    element: <PrivacyView />,
  },
  {
    path: "/:companyId/roles",
    element: <RolesView />,
  },
  {
    path: "/:companySlug",
    element: <LaunchPage />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
