import React, { useEffect, useState } from "react";

import SyncIcon from '@mui/icons-material/Sync';
import { Avatar, Box, Button, CircularProgress, Typography, styled } from "@mui/material";
import { CompanyData, Exclusions, PreviewData } from "../utils/types";
import { UseMutateFunction } from "react-query";

const NameAndTagline = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "> h2": {
    lineHeight: "48px",
    "@media print": {
      fontSize: "24px",
    },
  },
});

export const LogoContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasLaunched',
})(({ hasLaunched }: { hasLaunched: boolean }) => ({
  position: "relative",
  "&.loading, &:hover": {
    cursor: hasLaunched ? "default" : "pointer",
    ".overlay": {
      opacity: hasLaunched ? 0 : 1,
    },
  },
  ".overlay": {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "50px",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    opacity: 0,
    position: "absolute",
    top: 0,
    transition: "300ms all",
    width: "100%",
  },
  "svg": {
    pointerEvents: "none",
  },
}));

export const TextContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasLaunched',
})(({ hasLaunched }: { hasLaunched: boolean }) => ({
  position: "relative",
  "&.loading, &:hover": {
    cursor: hasLaunched ? "default" : "pointer",
    ".overlay": {
      opacity: hasLaunched ? 0 : 1,
    },
  },
  ".overlay": {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    opacity: 0,
    position: "absolute",
    top: 0,
    transition: "300ms all",
    width: "100%", 
  },
  "svg": {
    pointerEvents: "none",
  },
}));

const CompanyContainer = styled(Box)(({ theme }) => ({
  gap: "16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  "@media print": {
    margin: "24px 0 24px",
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column"
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: "100px",
  width: "100px",
  "@media print": {
    height: "48px",
    width: "48px",
  },
  [theme.breakpoints.down('sm')]: {
    margin: "0 auto",
    height: "auto",
    width: "50%",
  },
}));

interface CompanyProps {
  companyData: PreviewData | CompanyData | undefined;
  generateNewName: UseMutateFunction<void, unknown, string[], unknown>;
  generateNewLogo: UseMutateFunction<void, unknown, void, unknown>;
  generateNewTagline:  UseMutateFunction<void, unknown, string[], unknown>;
  hasLaunched: boolean;
  isLoadingCompanyData: boolean;
  isGeneratingDescription: boolean;
  isGeneratingLogo: boolean;
  isGeneratingName: boolean;
  isGeneratingTagline: boolean;
};

export const Company = ({
  companyData,
  generateNewName,
  generateNewLogo,
  generateNewTagline,
  hasLaunched,
  isLoadingCompanyData,
  isGeneratingDescription,
  isGeneratingLogo,
  isGeneratingName,
  isGeneratingTagline,
}: CompanyProps) => {

  const [exclusions, setExclusions] = useState<Exclusions>({ name: [], tagline: [] });
  const [displayedLogo, setDisplayedLogo] = useState<string | undefined>();

  useEffect(() => {
    if (!companyData?.logo?.length && displayedLogo) setDisplayedLogo(undefined);
    if (companyData?.logo?.length && displayedLogo !== companyData.logo[companyData.logo.length - 1]) setDisplayedLogo(companyData.logo[companyData.logo.length - 1])
  }, [companyData?.logo, displayedLogo]);

  useEffect(() => {
    if (companyData?.name && !exclusions.name.some(n => n === companyData.name)) {
      setExclusions(prevExclusions => {
        const tempNameExclusions = prevExclusions.name || []

        tempNameExclusions.push(companyData.name as string);

        return ({ ...prevExclusions, name: tempNameExclusions })
      });
    }

    if (companyData?.tagline && !exclusions.tagline?.some(n => n === companyData.tagline)) {
      setExclusions(prevExclusions => {
        const tempTaglineExclusions = prevExclusions.tagline || []

        tempTaglineExclusions.push(companyData.tagline as string);

        return ({ ...prevExclusions, tagline: tempTaglineExclusions })
      });
    }
  }, [companyData?.name, companyData?.tagline, exclusions]);

  if (isLoadingCompanyData || !companyData) return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );

  return (
    <>
      <CompanyContainer sx={{ mb: { xs: 2, sm: 1 } }}>
        <Box>
          <LogoContainer hasLaunched={hasLaunched} className={isGeneratingLogo ? "loading" : ""} sx={{ opacity: companyData.logo ? 1 : 0, transition: "300ms opacity ease-in-out" }}>
            <StyledAvatar alt={companyData.name} src={displayedLogo} />
            <Button disabled={hasLaunched} onClick={() => generateNewLogo()} className="overlay" sx={{ width: { xs: "48px", sm: "100px" } }}>
              {isGeneratingLogo ? <CircularProgress /> : <SyncIcon sx={{ color: "white", fontSize: "36px" }} />}
            </Button>
          </LogoContainer>
        </Box>
        <NameAndTagline>
          <TextContainer hasLaunched={hasLaunched} className={isGeneratingName ? "loading" : ""} sx={{ opacity: companyData.name ? 1 : 0, transition: "300ms opacity ease-in-out" }}>
            <Typography variant="h2" color="text.secondary" sx={{ textAlign: { xs: "center", sm: "left" }, fontSize: { xs: "26px", sm: "60px" }, fontWeight: "500", "@media print": { fontSize: "32px" } }}>{companyData.name}</Typography>
            <Button disabled={hasLaunched} onClick={() => generateNewName(exclusions.name)} className="overlay">
              {isGeneratingName ? <CircularProgress /> : <SyncIcon sx={{ color: "white", fontSize: "36px" }} />}
            </Button>
          </TextContainer>
          <TextContainer hasLaunched={hasLaunched} className={isGeneratingTagline ? "loading" : ""} paddingLeft="4px" sx={{ opacity: companyData.tagline ? 1 : 0, transition: "300ms opacity ease-in-out" }}>
            <Typography color="text.secondary" sx={{ fontSize: { xs: "12px", sm: "16px" }, textAlign: { xs: "center", sm: "left" } }}>{companyData.tagline}</Typography>
            <Button disabled={hasLaunched} onClick={() => generateNewTagline(exclusions.tagline)} className="overlay">
              {isGeneratingTagline ? <CircularProgress /> : <SyncIcon sx={{ color: "white", fontSize: "20px" }} />}
            </Button>
          </TextContainer>
        </NameAndTagline>
      </CompanyContainer>
      <Box mt={{ xs: 0, sm: 1 }} sx={{ height: { xs: "calc(100vh - 500px)", sm: "300px" }, overflow: "auto" }}>
        <Typography color="text.secondary" mb={2} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>{companyData.description}</Typography>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column" mt={isGeneratingLogo || isGeneratingDescription || isGeneratingName || isGeneratingTagline ? 2 : 0} gap={isGeneratingLogo || isGeneratingDescription || isGeneratingName || isGeneratingTagline ? 1 : 0} justifyContent="center">
        {(isLoadingCompanyData || isGeneratingDescription || isGeneratingLogo || isGeneratingName || isGeneratingTagline)
          ? (
            <>
              <CircularProgress />
              <Typography>{isGeneratingName ? "Generating name" : isGeneratingDescription ? "Generating description" : isGeneratingTagline ? "Generating tagline" : isGeneratingLogo ? "Generating logo" : ""}</Typography>
            </>
          )
          : <Typography textAlign="center" variant="subtitle2" sx={{ lineHeight: "16px" }}>{hasLaunched ? "After launching your page, your company data is permanent." : "Click the logo, etc to reroll your company name, tagline, and logo."}</Typography>}
      </Box>
    </>
  );
};

export default Company;