import React, { useEffect, useState } from 'react';

import { Alert, AlertTitle, Box, Card, CardContent, Dialog, Button, CircularProgress, Link, List, ListItem, Typography, styled, ListItemButton, useMediaQuery, Divider} from "@mui/material";
import { useMutation, useQuery } from "react-query";

import App from "../App";
import Company from "../components/Company";
import Create from "../components/Create";
import OptionsMenu from "../components/OptionsMenu";
import { CompanyData, LaunchPageData, PreviewData, UserData } from '../utils/types';
import { postLaunchPage } from '../utils/queries/launchPages';
import { deleteCompany, fetchNewLogo, fetchNewName, fetchNewTagline } from '../utils/queries/companies';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

type CompanyProps = {
  _id: string;
  name: string;
  slug: string;
}

const VenturesContainer = styled(Box)({
  display: "flex",
  maxHeight: "100%",
  overflow: "hidden",
  width: "100%",
});

interface DashboardViewProps {
  handleSignIn: () => void;
  isFetchingJWT: boolean;
  isLoadingUserData: boolean;
  isSigningIn: boolean;
  setToast: ((toast?: { message: string, severity: string }) => void);
  userData?: UserData;
  userToken?: string;
}

const DashboardView = ({ handleSignIn, isFetchingJWT, isLoadingUserData, isSigningIn, setToast, userData, userToken }: DashboardViewProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate()

  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [previewData, setPreviewData] = useState<PreviewData>({});
  const [isGenerating, setIsGenerating] = useState(false);

  const { data: companies , isLoading, refetch: refetchCompanies } = useQuery<CompanyProps[], Error>({
    enabled: Boolean(userData),
    queryKey: ['companies'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (res) => {
      if (!selectedCompany && res?.length) setSelectedCompany(res[0]._id);
    }
  });

  const { data: companyData, isLoading: isLoadingCompanyData, refetch: refetchCompanyData } = useQuery<CompanyData, Error>({
    enabled: Boolean(selectedCompany),
    onError: (e) => {
      setToast({
        message: "Error fetching company data",
        severity: "error",
      })
    },
    queryKey: ['companies', selectedCompany],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: generateNewLogo, isLoading: isGeneratingLogo } = useMutation<void, unknown, void, unknown>(() => fetchNewLogo(companyData, true, userToken), {
    onSuccess: () => {
      refetchCompanies();
      refetchCompanyData();
    },
  });

  const { mutate: generateNewName, isLoading: isGeneratingName } = useMutation<void, unknown, string[], unknown>((excludedNames: string[]) => fetchNewName(undefined, companyData, true, excludedNames, userToken), {
    onSuccess: () => {
      refetchCompanies();
      refetchCompanyData();
    },
  });

  const { mutate: generateNewTagline, isLoading: isGeneratingTagline } = useMutation<void, unknown, string[], unknown>((excludedTaglines) => fetchNewTagline(companyData, true, excludedTaglines, userToken), {
    onSuccess: () => {
      refetchCompanies();
      refetchCompanyData();
    },
  });

  const { mutate: handleDeleteCompany, isLoading: isDeletingCompany } = useMutation(deleteCompany, {
    onSuccess: () => {
      if (!isDeletingCompany) refetchCompanies();
    },
  });

  const { data: launchPageData, isLoading: isLoadingLaunchPageData, refetch: refetchLaunchPageData } = useQuery<LaunchPageData, Error>({
    enabled: Boolean(selectedCompany),
    queryKey: ['launchpages', selectedCompany],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: createLaunchPage, isLoading: isCreatingLaunchPage } = useMutation((companyData: CompanyData) => postLaunchPage({
    slug: companyData.slug,
    companyId: companyData._id,
  }), {
    onSuccess: res => {
      navigate(`/${res.launchPage.slug}`);
    }
  });

  useEffect(() => {
    if (launchPageData?.companyId !== selectedCompany) refetchLaunchPageData();
  }, [selectedCompany, launchPageData?.companyId, refetchLaunchPageData]);

  useEffect(() => {
    if (selectedCompany && previewData.id) setPreviewData({});
  }, [selectedCompany, previewData])

  const companyCard = (
    <Card variant={isMobile ? "elevation" : "outlined"} sx={{ height: "100%",  transition: "300ms all", width: Boolean(selectedCompany || previewData.id) ? { xs: "100%", sm: "70%" } : 0 }}>
      <CardContent sx={{ height: "100%" }}>
        <Box display="flex" height="100%" flexDirection="column" alignItems="center" justifyContent="space-between" gap={1} mt={isMobile ? 0 : 2}>
          <Company
            generateNewLogo={generateNewLogo}
            generateNewName={generateNewName}
            generateNewTagline={generateNewTagline}
            companyData={previewData.id ? previewData : (companyData?._id === selectedCompany ? companyData : undefined)}
            isLoadingCompanyData={isLoadingCompanyData}
            isGeneratingLogo={isGeneratingLogo || Boolean(previewData?.id && !previewData.logo)}
            isGeneratingDescription={Boolean(previewData?.id && !previewData.description)}
            isGeneratingName={isGeneratingName || Boolean(previewData?.id && !previewData.name)}
            isGeneratingTagline={isGeneratingTagline || Boolean(previewData?.id && !previewData.tagline)}
            hasLaunched={isLoadingLaunchPageData || Boolean(launchPageData)}
          />
          
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" borderTop={(theme) => `1px solid ${theme.palette.divider}`} pt={3} sx={{ opacity: selectedCompany ? 1 : 0 }}>
            <Button variant='outlined' href={`/${companyData?._id}/roles`}>Business Dashboard</Button>
            {!launchPageData && !isLoadingLaunchPageData && (
              <>
                {selectedCompany && companyData?.slug && <LoadingButton variant="outlined" loading={isCreatingLaunchPage} sx={{ textTransform: "none" }} onClick={() => createLaunchPage(companyData)}>{`Launch https://venturify.xyz/${companyData.slug}`}</LoadingButton>}
              </>
            )}
            {isLoadingLaunchPageData && <CircularProgress />}
            {Boolean(launchPageData) && (
              <Box>
                <Box display="flex" gap={1}>
                  <Typography>Launch page:</Typography>
                  <Link target="_blank" href={launchPageData?.slug}><Typography>{`https://venturify.xyz/${launchPageData?.slug}`}</Typography></Link>
                </Box>
                <Typography>{`Views: ${launchPageData?.pageViews}`}</Typography>
                <Typography>{`Waitlist length: ${launchPageData?.waitlist.length}`}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Box display="flex" flexDirection="column" overflow="hidden" justifyContent={companies?.length ? "initial" : "center"} sx={{ height: "100%" }}>
        <Box my="auto">
          <Create
            handleOnSuccess={newId => {
              setIsGenerating(false);
              refetchCompanies();
              setSelectedCompany(newId);
            }}
            hasCompany={Boolean(companies?.length)}
            showTitle={!companies?.length && !previewData?.id}
            isFetchingJWT={isFetchingJWT}
            isGenerating={isGenerating}
            onSubmit={() => {
              setSelectedCompany(undefined);
              setIsGenerating(true);
            }}
            previewData={previewData}
            isLoadingUserData={isLoadingUserData}
            setPreviewData={setPreviewData}
            userData={userData}
          />

          {isLoading && <CircularProgress />}
          {Boolean(userData) && !userData?.grantedProducts.some((product: string) => product === 'venturify') && (
            <Alert severity="success">
              <AlertTitle>Welcome to the free version!</AlertTitle>
              <Box display="flex" gap={1} sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                <Typography>You can create up to 1 company.</Typography>
                <Link href="/about"><Typography>Learn more</Typography></Link>
              </Box>
            </Alert>
          )}
          {!userData && isSigningIn && (
            <Alert severity="info">
              <AlertTitle>Sign-in in progress</AlertTitle>
              <Box display="flex" gap={1} sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                <Typography>Finish signing in from the popup in order to join the waitlist.</Typography>
              </Box>
            </Alert>
          )}
        </Box>
        {Boolean(userData) && (Boolean(companies?.length) || previewData?.id) && (
          <VenturesContainer>
            <List sx={{ height: "100% - 100px", overflow: "auto", width: (selectedCompany || previewData.id) ? { xs: "100%", sm: "30%" } : "100%" }}>
              {!isLoading && companies?.map(({ _id: companyId, name }) => (
                <ListItem disablePadding key={companyId} sx={{ width: "100%" }}>
                  <ListItemButton disabled={isGenerating} selected={selectedCompany === companyId} onClick={() => setSelectedCompany(companyId)}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography color="text.primary" sx={{ fontFamily: "PTSerif", fontWeight: "500", fontSize: "20px" }}>{name}</Typography>
                      <OptionsMenu menuItems={[
                          {
                            label: "Delete company",
                            onClick: () => {
                              setSelectedCompany(undefined);
                              handleDeleteCompany(companyId);
                            },
                            disabled: !userData?.grantedProducts.some((product: string) => product === 'venturify') && companies.length
                          }
                        ]} sx={{ marginLeft: "auto" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {!isMobile && companyCard}
          </VenturesContainer>
        )}
        {!userData && (
          <LoadingButton
            disabled={Boolean(userData) || isSigningIn}
            onClick={handleSignIn}
            loading={isSigningIn}
            variant="contained"
            size='large'
            sx={{ bgcolor: "text.primary", width: "fit-content", mx: "auto" }}
          >
            {"Log in or Sign up to start creating"}
          </LoadingButton>
        )}
        <Box display="flex" alignItems="center" justifyContent="center" height="28px" mt="auto" p={3}>
          <Button variant='text' href="/about">About</Button>
          <Divider orientation='vertical'/>
          <Button variant='text' href="/privacy">Privacy</Button>
        </Box>
      </Box>
      <Dialog PaperProps={{ elevation: 0, sx: { backgroundColor: "transparent", height: { xs: "calc(100vh - 100px)", sm: "auto" }, padding: 0, margin: 0, width: { xs: "calc(100% - 16px)", sm: "auto" } } }} fullWidth={isMobile} open={((Boolean(selectedCompany) || Boolean(previewData?.id)) && isMobile)} onClose={() => {
        if (selectedCompany) setSelectedCompany(undefined);
      }}>
        {(Boolean(selectedCompany) || Boolean(previewData?.id)) && companyCard}
      </Dialog>
    </>
  )
};

const View = () => (
  <App containerSx={{ overflow: "hidden" }} Component={DashboardView} />
)

export default View;