import React, { useCallback, useEffect, useState } from "react";

import { fetchTasks, clearAllTasks, createTask } from "../utils/queries/tasks";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Card, CardActionArea, CardContent, Chip, CircularProgress, Container, IconButton, List, ListItem, ListItemText, Modal, Theme, Toolbar, Typography, styled } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import OptionsMenu from "../components/OptionsMenu";
import CalloutHeader from "../components/CalloutHeader";
import { CompanyData, Role, Task } from "../utils/types";
import { askChatGPT } from "../utils/queries/openai";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from "@mui/lab";
import { fetchJWTToken } from "../utils/queries/users";
import { getAuth } from "firebase/auth";
import { Home } from "@mui/icons-material";
import { updateCompany } from "../utils/queries/companies";

const RolesContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    maxHeight: "100vh",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
    "@media print": {
      flexDirection: "column",
      maxHeight: "none",
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      maxHeight:  "none",
    },
  }));
  
  const RoleColumn = styled(Box)({
    padding: "0 16px 16px",
    "&:not(:last-of-type)": {
      borderRight: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    },
  });
  
  const RoleDescription = styled(Typography)({
    maxHeight: "100px",
    overflow: "auto",
    "@media print": {
      maxHeight: "none",
    },
  })
  
  const RoleTitle = styled(Box)({
    display: "flex",
    alignItems: "center",
    height: "100px",
    justifyContent: "center",
    "@media print": {
      height: "initial",
    },
  });
  
  const TasksContainer = styled(Box)({
    maxHeight: "calc(100% - 284px)",
    overflow: "auto",
    padding: "16px",
    margin: "0 -16px",
    "&> div": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    "@media print": {
      maxHeight: "none",
      "> div": {
        gap: 0,
      },
    },
  });
  
  const TaskCard = styled(Card)({
    "@media print": {
      boxShadow: "none",
      ".MuiCardContent-root, p": {
        padding: 0,
        margin: 0,
      },
    },
  });

  const ModalBox = styled(Card)({
    left: '50%',
    position: "absolute",
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: "32px",
  });

  const TasksHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "@media print": {
      "&.empty": {
        display: "none",
      },
      "> button": {
        display: "none",
      }
    }
  });

  const StyledToolbar = styled(Toolbar)({
    alignItems: "center",
    display: "flex",
    gap: "24px",
    padding: 0,
  });

const RolesView = () => {
    const { companyId } = useParams();
    const auth = getAuth();
    
    const [userToken, setUserToken] = useState(localStorage.getItem('jwtToken') || undefined);
    const [isCreatingTasks, setIsCreatingTasks] = useState<string | undefined>();
    const [isGeneratingRoles, setIsGeneratingRoles] = useState(false)
    const [displayedTask, setDisplayedTask] = useState<Task | undefined>();

    const { mutate: getJWTToken, isLoading: isFetchingJWT } = useMutation(fetchJWTToken, {
        onSuccess: (res) => {
          localStorage.setItem('jwtToken', res);
          setUserToken(res);
        },
      });

    const { data: companyData, refetch: refetchCompanyData } = useQuery<CompanyData, Error>({
        enabled: Boolean(companyId),
        queryKey: ['companies', companyId],
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      });
    
    const { data: tasks, refetch: refetchTasks } = useQuery(['tasks'], () => fetchTasks(companyId!, userToken!), {
        enabled: Boolean(companyId && userToken),
    });

    const { mutate: handleClearAllTasks } = useMutation(() => clearAllTasks(companyId!, userToken!), {
        onSuccess: refetchTasks
    });

    const { mutate: handleCreateTask } = useMutation((newTask: {}) => createTask(newTask, userToken!), {
        onSuccess: refetchTasks,
        onSettled: () => setIsCreatingTasks(undefined)
    });

    const handlePrint = () => {
        window.print();
    }

    const generateRoles = useCallback(async () => {
        if (!companyData) return;

        const roles: Role[] = [];

        setIsGeneratingRoles(true);

        const rolesRaw = await askChatGPT(`${companyData.description} Minumum roles needed for a cost-effective minimum-viable launch| [List of roles separated by semi-colons]`);
        const rolesRaw2 = rolesRaw.split("\n").filter((i: string) => i?.length > 1);
        const rolesForParsing = rolesRaw2.length > 1 ? rolesRaw2 : rolesRaw2[0].split("; ") ;

        const roleTitles: string[] = rolesForParsing.map((i: string) => {
            const a = i.split(". ")

            return a[a.length - 1].split(".").join("").split(";").filter(a => a?.length).join("")
        });

        const promises = roleTitles.map(title => {
            const promise = new Promise(async (resolve) => {
              const roleDescription = await askChatGPT(`${companyData.description}. Description of role for ${title}| [Role description]`)
    
              resolve(roles.push({ title, description: roleDescription }));
            });
    
            return promise;
          })
    
          await Promise.all(promises);

          await updateCompany(companyId, {
            roles
          }, userToken);

          setIsGeneratingRoles(false);
          refetchCompanyData();
    }, [companyData, companyId, refetchCompanyData, userToken])

    const generateTasks = async (role: string) => {
        if (!companyData?.roles) return;
    
        setIsCreatingTasks(role)
    
        const taskListByRole = tasks?.filter(task => task.role === role).map(task => task.title).join(', ')
    
        const addNewTask = async (rawTask: string) => {
          const unparsedTask = rawTask.split(": ").filter(item => item?.length > 1)
    
          const description = unparsedTask[1]
          const title = unparsedTask[0][0] >= "0" && unparsedTask[0][0] <= "9" ? unparsedTask[0].substr(3) : unparsedTask[0];
    
          const taskPriority = await askChatGPT(`Prioritization category for ${role} role's task of ${title}`);
          const acceptanceCriteriaRaw = await askChatGPT(`Acceptance criteria for ${role} role's task of ${title}`);
          const acceptanceCriteria = acceptanceCriteriaRaw.split("\n").filter((i?: any) => Boolean(i));
    
          const task = {
            acceptanceCriteria,
            companyId,
            description,
            priority: taskPriority,
            role,
            status: "TODO",
            title,
          }
    
          handleCreateTask(task)
        }
    
        const roleDescription = companyData.roles.find(cr => cr.title === role);
        const newTasksUnparsed = await askChatGPT(`${companyData.description} ${roleDescription?.description}. Task for ${roleDescription?.title} ${taskListByRole?.length ? ` Excluding tasks: ${taskListByRole}, none.` : ""}| [Task title]| [Task description]`)
        const newTasks = newTasksUnparsed.split('\n').filter((item: string) => item.length > 1)
    
        newTasks.forEach((newTask: string) => addNewTask(newTask));
      }

      useEffect(() => {
        if (companyData && !companyData.roles.length) generateRoles();
      }, [companyData, companyData?.roles.length, generateRoles]);

      useEffect(() => {
        const storedUserToken = localStorage.getItem('jwtToken');
    
        if (storedUserToken && !userToken) {
          setUserToken(JSON.parse(storedUserToken));
        }
        
        auth.onAuthStateChanged(async (firebaseUser) => {
          if (firebaseUser && !isFetchingJWT && !userToken) {
            getJWTToken({
              userId: firebaseUser.uid,
              email: firebaseUser.email,
            });
          } else if (!firebaseUser) {
            localStorage.removeItem('jwtToken');
            setUserToken(undefined);
          }
        });
        
      }, [isFetchingJWT, userToken, auth, getJWTToken]);

    if (!userToken) return null;

    return (
        <>
            <AppBar position="fixed" sx={{ bgcolor: (theme) => theme.palette.background.paper, "@media print": { display: "none" } }}>
                <Container maxWidth="xl">
                    <StyledToolbar>
                    <IconButton href="/"><Home color="primary" /></IconButton>
                    <OptionsMenu menuItems={[
                        {
                        label: "Print this plan",
                        onClick: handlePrint,
                        },
                        {
                        label: "Clear all tasks",
                        onClick: handleClearAllTasks,
                        disabled: !tasks?.length
                        },
                    ].filter(i => Boolean(i))} sx={{ marginLeft: "auto" }} />
                    </StyledToolbar>
                </Container>
            </AppBar>

            <Box mt={10} mx={2}>
                <CalloutHeader rtl={true} header="Start Working" content={`Let's divide the work at ${companyData?.name} into tasks that fit under ${companyData?.roles?.length} different roles. Anyone can take on any number of roles, (one person could even do them all).`} />
                <RolesContainer>
                    {companyData?.roles?.map(role => {
                        const roleTasks = tasks?.filter(task => task.role === role.title);

                        return (
                            <RoleColumn key={`container_${role.title}`}>
                                <Accordion sx={{ display: { xs: "block", sm: "none" }, "@media print": { display: "none" } }}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${role.title}-content`}
                                    id={`${role.title}-header`}
                                    >
                                    <Typography color="text.secondary">{role.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <RoleDescription color="text.secondary">
                                        {role.description}
                                    </RoleDescription>
                                    </AccordionDetails>
                                </Accordion>

                                <Box sx={{ display: { xs: "none", sm: "inline-block" } }} width="200px">
                                    <RoleTitle>
                                    <Typography color="text.secondary" align="center" variant="h5">{role.title}</Typography>
                                    </RoleTitle>
                            
                                    <Box height="200px" overflow="auto">
                                    <Typography color="text.secondary">{role.description}</Typography>
                                    </Box>
                            
                                    <TasksHeader className={`${!roleTasks?.length ? "empty" : ""}`}>
                                    <Typography color="text.secondary" align="center" sx={{ lineHeight: "16px", fontWeight: "bold" }}>Tasks</Typography>
                                    <Chip variant="outlined" size="small" label={<Typography color="text.secondary" align="center" variant="subtitle2" sx={{ fontWeight: "bold" }}>{roleTasks?.length || 0}</Typography>} />
                                    <LoadingButton fullWidth disabled={Boolean(isCreatingTasks)} loading={isCreatingTasks === role.title} onClick={() => generateTasks(role.title)}>Generate</LoadingButton>
                                    </TasksHeader>
                            
                                    <TasksContainer>
                                    <div>
                                        {roleTasks?.map((task, taskIndex) => (
                                        <TaskCard key={`${task.title}-${taskIndex}`}>
                                            <CardActionArea onClick={() => setDisplayedTask(task)}>
                                            <CardContent>
                                                <Typography color="text.secondary" mt={1} sx={{ fontWeight: "bold" }}>{task.title}</Typography>
                                            </CardContent>
                                            </CardActionArea>
                                        </TaskCard>
                                        ))}
                                    </div>
                                    </TasksContainer>
                                </Box>
                            </RoleColumn>
                        )
                    })}
                    {isGeneratingRoles && (
                        <Box width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                            <Typography>Generating roles</Typography>
                            <CircularProgress />
                        </Box>
                    )}
                </RolesContainer>
            </Box>
            <Modal open={Boolean(displayedTask)} onClose={() => setDisplayedTask(undefined)}>
                <ModalBox>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{displayedTask?.title}</Typography>
                    <Box mt={2} display="flex" alignItems="center" gap={1}>
                    <Typography>Priority:</Typography>
                    <Chip label={<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{displayedTask?.priority}</Typography>} />
                    </Box>
                    <Typography marginY={2}>{displayedTask?.description}</Typography>
                    <Typography sx={{fontWeight: "bold" }}>Acceptance criteria:</Typography>
                    <List>
                        {displayedTask?.acceptanceCriteria.map(item => <ListItem key={item}><ListItemText>{item}</ListItemText></ListItem>)}
                    </List>
                </ModalBox>
            </Modal>
        </>
    )
};

export default RolesView;