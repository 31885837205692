import React, { useEffect, useState } from 'react';

import { Box, Container, Skeleton, TextField, Typography, styled } from '@mui/material';
import { useQuery } from 'react-query';

import { useParams } from 'react-router-dom';
import { CompanyData, LaunchPageData } from '../utils/types';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { postToWaitlist, updatePageViews } from '../utils/queries/launchPages';

const StyledTextField = styled(TextField)`
  & label {
    color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    border-color: white;
    &.Mui-focused fieldset {
      border-color: white;
    }
  & .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
}`;

const LaunchContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'heroImage',
})<{ heroImage?: string }>(({ heroImage }) => ({
  backgroundImage: heroImage ? `url(${heroImage})` : "none",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: 'cover',
  height: "100vh",
  overflow: "hidden",
  position: "relative",
  width: "100vw",
  "h1": {
    margin: 0,
  },
  "& .MuiLoadingButton-root.Mui-disabled": {
    color: "#fff",
  },
}));

const LaunchPage = () => {
  const { companySlug } = useParams();

  const [email, setEmail] = useState("");
  const [joined, setJoined] = useState(false);

  const { data: launchPageData, refetch: refetchLaunchPageData } = useQuery<LaunchPageData, Error>({
    queryKey: ['launchpages', companySlug],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: companyData } = useQuery<CompanyData, Error>({
    enabled: Boolean(launchPageData?.companyId),
    queryKey: ['companies', launchPageData?.companyId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: handleJoinWaitlist, isLoading: isJoining } = useMutation(() => postToWaitlist({ email, slug: launchPageData?.slug as string }), {
    onSuccess: () => {
      refetchLaunchPageData();
      setJoined(true);
    },
  });

  const { mutate: updateViewCount } = useMutation(() => updatePageViews({ slug: companySlug }));

  useEffect(() => {
    updateViewCount();
  }, [updateViewCount])

  return (
    <LaunchContainer heroImage={launchPageData?.heroImage}>
      <Container maxWidth="xl" sx={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}>
        {companyData ? (
          <Box display="flex" borderRadius="10px" alignItems="center" flexDirection="column" justifyContent="center"sx={{ backgroundColor: "rgba(0,0,0,0.1)", p: { xs: 2, sm: 8 } }}>
            <Box sx={{ fontFamily: "Oswald, sans-serif" }} display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Typography textAlign="center" sx={{ color: "white", fontSize: { xs: "3em", sm: "6em" }, textShadow: "rgba(0, 0, 0, 0.27) 0rem 0.69rem 0.9375rem" }}>{companyData.name}</Typography>
              <Typography textAlign="center" fontWeight={400} sx={{ color: "#fff", fontSize: { xs: "1em", sm: "2em" } }}>{companyData.tagline}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" height={200} gap={2} border="3px solid #fff" justifyContent="center" mt={6} sx={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: { xs: "50px", sm: "100px" }, px: { xs: 4, sm: 10 }, width: { xs: "100%", sm: 540 } }}>
              <Typography textAlign="center" sx={{ color: "white" }}>{`${launchPageData?.waitlist.length} email${launchPageData?.waitlist.length === 1 ? "" : "s"} on the waitlist`}</Typography>
              <StyledTextField inputProps={{ style: { color: "#fff" } }} type="email" autoFocus value={email} label="Email" onChange={e => setEmail(e.target.value)} fullWidth size='small' />
              <LoadingButton className='submit' loading={isJoining} disabled={!email.length || joined} fullWidth variant="contained" sx={{ bgcolor: "#fff", color: "#222", mt: 1 }} onClick={() => handleJoinWaitlist()}>{joined ? "Joined" : "Join the waitlist"}</LoadingButton>
            </Box>
          </Box>
        ) : <Skeleton />}
      </Container>
    </LaunchContainer>
  )
};

export default LaunchPage;