import React from 'react';

import { Box, Container } from "@mui/material";
import App from '../App';

const Privacy = () => (
  <Box height="100%" width="100%">
    <Container maxWidth="xl" sx={{ height: { xs: "calc(100vh - 80px)", sm: "calc(100% - 80px)" }, padding: { xs: 0, sm: "initial" } }}>
      <object data="https://res.cloudinary.com/dmyytgv2d/image/upload/v1677697049/Venturify_Privacy_Policy_nwsaws.pdf" type="application/pdf" width="100%" height="100%">
        <p>Link <a href="https://res.cloudinary.com/dmyytgv2d/image/upload/v1677697049/Venturify_Privacy_Policy_nwsaws.pdf">to the PDF!</a></p>
      </object>
    </Container>
  </Box>
);

const PrivacyView = () => (
  <App Component={Privacy} />
);

export default PrivacyView;