import React from 'react';

import { Box } from "@mui/material";

import App from "../App";
import WaitlistCard from '../components/WaitlistCard';
import { UserData } from '../utils/types';

interface AboutProps {
  handleSignIn: () => void;
  isLoadingUserData: boolean;
  isSigningIn: boolean;
  userData?: UserData;
};

const About = ({
  handleSignIn,
  isLoadingUserData,
  isSigningIn,
  userData,
}: AboutProps) => {
  return (
    <Box display="flex" paddingTop={4} sx={{ flexDirection: { xs: "column", sm: "row" } }}>
      <WaitlistCard flat userData={userData} isSigningIn={isSigningIn || isLoadingUserData} handleSignIn={handleSignIn} />
      <Box display="flex" alignItems="center">
        
      </Box>
    </Box>
  )
};

const AboutView = () => (
  <App
    containerSx={{ height: "100%", overflow: "auto", maxWidth: "100%" }}
    Component={About}
  />
);

export default AboutView;