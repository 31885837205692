import React, { SyntheticEvent, useState } from 'react';

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface OptionsMenuProps {
  menuItems: any[];
  sx?: {};
}

const OptionsMenu = ({ menuItems, sx }: OptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!menuItems) return null;

  return (
    <Box sx={sx}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => {
          event.stopPropagation()
          handleClick(event);
        }}
      >
        <MoreVertIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(({ disabled, label, onClick }) => (
          <MenuItem key={label} disabled={disabled} onClick={(event) => {
            event.stopPropagation()
            onClick(event)
            handleClose()
          }}>{label}</MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default OptionsMenu;
