import { Box, Typography } from "@mui/material";
import React from "react";

const CalloutHeader = ({ header, content, rtl }) => {
  const headerContainer = (
    <Box height="100%" display="flex" alignItems="center" boxSizing="border-box">
      <Typography color="text.primary" textAlign="center" variant="h4" width="400px">{header}</Typography>
    </Box>
  );
  const contentContainer = (
    <Box height="100%" boxSizing="border-box" padding="32px" sx={{ bgcolor: "text.primary" }}>
      <Typography color="primary.contrastText" textAlign="center" sx={{ fontSize: "18px" }}>{content}</Typography>
    </Box>
  );

  return (
  <Box display="flex" alignItems="center" height="120px" sx={{ borderBottom: (theme) => `1px solid ${theme.palette.text.primary}`, borderTop: (theme) => `1px solid ${theme.palette.text.primary}` }}>
    {rtl ? contentContainer : headerContainer}
    {rtl ? headerContainer : contentContainer}
  </Box>
)};

export default CalloutHeader;