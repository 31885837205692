import React from  'react';

import { Box, Typography, Card, CardContent, CardActions, Button, Divider } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import HeroContainer from './HeroContainer';

import { UserData } from "../utils/types";

interface WaitlistCardProps {
  companyName?: string;
  handleSignIn: () => void;
  heroImage?: string;
  isSigningIn: boolean;
  flat?: boolean;
  userData?: UserData;
}

const WaitlistCard = ({
  flat,
  handleSignIn,
  isSigningIn,
  userData,
}: WaitlistCardProps) => (
  <Box overflow="hidden">
    <Card elevation={flat ? 0 : 2} sx={{ height: "calc(100% - 28px)", width: { xs: "100%", sm: "400px" } }}>
      <CardContent>
        <HeroContainer companyName="Venturify" heroImage = "https://res.cloudinary.com/dmyytgv2d/image/upload/v1677044740/dh6ha4vbxs15unfxxgmw.png" />
        <Box pt={2} maxHeight="calc(100vh - 392px)" overflow="auto">
          <Typography variant="h5">Join the Waitlist</Typography>
          <Typography ml={1} sx={{ fontWeight: "bold" }}>Alpha (Current) Features</Typography>
          <ul>
            <li><Typography>Generate a company name, logo, tagline, roles, and tasks from a single prompt.</Typography></li>
            <li><Typography>{"Launch a landing page at https://venturify.com/<your-company>"}</Typography></li>
          </ul>
        </Box>
      </CardContent>
      <CardActions sx={{ padding: { xs: "0 16px 16px", sm: 4 } }}>
        <LoadingButton disabled={Boolean(userData) || isSigningIn} onClick={handleSignIn} loading={isSigningIn} fullWidth variant="contained" sx={{ bgcolor: "text.primary" }}>{`Join${userData ? "ed" : ""}`}</LoadingButton>
      </CardActions>
    </Card>
    <Box display="flex" alignItems="center" justifyContent="center" height="28px" padding={1}>
      <Button variant='text' href="/about" sx={{ color: flat ? "primary" : "primary.contrastText" }}>About</Button>
      <Divider orientation='vertical' sx={{ bgcolor: "primary.contrastText", height: "100%" }}/>
      <Button variant='text' href="/privacy" sx={{ color: flat ? "primary" : "primary.contrastText" }}>Privacy</Button>
    </Box>
  </Box>
);

export default WaitlistCard;