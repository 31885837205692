import React, { useEffect, useState } from "react";

import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { Alert, AlertColor, Box, Button, Container, Snackbar, styled, Typography, useMediaQuery} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMutation, useQuery } from 'react-query';

import ErrorBoundary from "./components/ErrorBoundary";

import HeroContainer from "./components/HeroContainer";
import { LoadingButton } from "@mui/lab";
import { fetchJWTToken } from "./utils/queries/users";

const firebaseConfig = {
  apiKey: "AIzaSyDDCa_mB89KWpNDjB2Ef6htZIuyCylYbkI",
  authDomain: "venturify.xyz",
  projectId: "venturify-963c5",
  storageBucket: "venturify-963c5.appspot.com",
  messagingSenderId: "1042437780187",
  appId: "1:1042437780187:web:eb4936cc8b5486721f92fe",
  measurementId: "G-SFX6DZQXMZ"
};

initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/userinfo.email');

const auth = getAuth();

const AppContainer = styled(Box)({
  height: "100vh",
  overflow: "auto",
  width: "100vw",
  "@media print": {
    height: "auto",
  }
})

const StyledSnackbar = styled(Snackbar)({
  "@media print": {
    display: "none",
  }
});

interface AppProps {
  Component: any;
  containerSx?: any;
};

const App = ({ containerSx, Component }: AppProps) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const [toast, setToast] = useState<{ message: string, severity: string } | undefined>()
  const [userToken, setUserToken] = useState(localStorage.getItem('jwtToken') || undefined);
  const [userData, setUserData] = useState();

  const handleGoogleSignIn = () => signInWithPopup(auth, provider);

  const handleGoogleSignOut = () => auth.signOut()
  .catch(() => {
    setToast({
      message: "Error signing out",
      severity: "error"
    })
  });

  const { mutate: handleSignIn, isLoading: isSigningIn } = useMutation(handleGoogleSignIn);
  const { mutate: handleSignOut, isLoading: isSigningOut } = useMutation(handleGoogleSignOut);

  const { mutate: getJWTToken, isLoading: isFetchingJWT } = useMutation(fetchJWTToken, {
    onSuccess: (res) => {
      localStorage.setItem('jwtToken', res);
      setUserToken(res);
    },
  });

  const { isLoading: isLoadingUserData } = useQuery({
    enabled: !userData && Boolean(userToken) && !isFetchingJWT,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: setUserData,
    queryKey: ['users'],
  });

  useEffect(() => {
    if (!toast) {
      if (isLoadingUserData || isFetchingJWT){
        setToast({
          message: "Signing in",
          severity: "success",
        }) 
      }
    } else if (toast?.message === "Signing in" && userData) setToast(undefined)
  }, [toast, isFetchingJWT, isLoadingUserData, userData])

  useEffect(() => {
    const storedUserToken = localStorage.getItem('jwtToken');

    if (storedUserToken && !userToken) {
      setUserToken(JSON.parse(storedUserToken));
    }
    
    auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && !isFetchingJWT && !userToken) {
        getJWTToken({
          userId: firebaseUser.uid,
          email: firebaseUser.email,
        });
      } else if (!firebaseUser) {
        localStorage.removeItem('jwtToken');
        setUserToken(undefined);
        setUserData(undefined);
      }
    });
    
  }, [isFetchingJWT, userToken, getJWTToken]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AppContainer sx={{ bgcolor: (theme) => theme.palette.background.paper }}>
          <Container sx={{ height: "100vh", margin: { xs: 0, sm: "0 auto" }, maxWidth: { xs: "100%", sm: "lg" }, ...containerSx }}>
            <StyledSnackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={Boolean(toast)} autoHideDuration={6000} onClose={() => setToast(undefined)}>
              <Alert severity={(toast?.severity || "info") as AlertColor} sx={{ width: '100%' }}>
                {toast?.message}
              </Alert>
            </StyledSnackbar>
            <Box height="80px">
              <Container maxWidth="xl" sx={{ display: "flex", height: "100%", justifyContent: userData ? 'space-between' : 'center' }}>
                <Button href="/" sx={{ minWidth: "200px", overflow: "hidden", textTransform: 'none' }}>
                  <HeroContainer companyName='Venturify' noHero />
                </Button>
                {Boolean(userData) && (
                  <LoadingButton variant="text" loading={isSigningOut} onClick={() => handleSignOut()} sx={{ minWidth: "100px", ml: "auto" }}>
                    <Typography sx={{ textTransform: 'none' }}>Log out</Typography>
                  </LoadingButton>
                )}
              </Container>
            </Box>
            <Box width="100%" height="calc(100% - 80px)">
              <Component userData={userData} isLoadingUserData={isLoadingUserData} setToast={setToast} isFetchingJWT={isFetchingJWT} userToken={userToken} handleSignIn={handleSignIn} handleSignOut={handleSignOut} isSigningIn={isSigningIn} isSigningOut={isSigningOut} />
            </Box>
          </Container>
        </AppContainer>
      </ErrorBoundary>
    </ThemeProvider>
  )};

export default App;
