import { askChatGPT, getGeneratedImage } from "./openai";
import { companiesURL } from "../urls";

// Function to create a new company
export const postCompany = async (companyData) => {
  const token = localStorage.getItem("jwtToken");
  try {
    const response = await fetch(companiesURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(companyData)
    });

    if (!response.ok) {
      throw new Error('Failed to create company');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCompany = (id, data, token) => fetch(`${companiesURL}/${id}`, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
  body: JSON.stringify(data),
});

export const fetchNewLogo = async (company, shouldUpdate, token) => {
  const logoDescription = await askChatGPT(`${company.prompt}. Simple description of a possible profile pic idea for ${company.name}. No text or font | [Image description]`);
  const logo = await getGeneratedImage("512x512", `${logoDescription}, centered`);

  return shouldUpdate ? updateCompany(company._id, { logo }, token) : logo;
};

export const fetchNewName = async (prompt, company, shouldUpdate, exclusions, token) => {
  const nameRaw = await askChatGPT(`${prompt || company?.description}${exclusions ? ` Do not suggesting anything the following names: ${exclusions.join(", or ")}` : ""}. A name for the enterprise| [name]`, 1, 8);
  const name = nameRaw.split(":").join("").split("\"").join("").trim();

  // Make a new slug to go with the new name
  const slugRaw = await askChatGPT(`${prompt}. A short, hyphenated or one-word url slug representing ${name}'s subdomain| [slug]`, 1, 8);
  const slug = slugRaw.split("\n").join("").split("").filter((c) => {
    return (c >= "0" && c <= "9") || (c >= "a" && c <= "z") || (c >= "A" && c <= "Z") || c === "-"
  }).join("").trim();

  if (!name.length) throw new Error('empty name');

  if (shouldUpdate) {
    await updateCompany(company?._id, { name, slug }, token);
  }

  return name;
};

export const fetchNewTagline = async (company, shouldUpdate, exclusions, token) => {
  const taglineRaw = await askChatGPT(`${company.description} Short, succint tagline for ${company.name}.${exclusions ? ` Do not suggesting the following taglines: ${exclusions.join(", or ")}` : ""}| [Tagline]`, 1, 24);
  const tagline = taglineRaw.split("\"").join("");

  if (!tagline.length) throw new Error('empty tagline');

  if (shouldUpdate) {
    await updateCompany(company?._id, { tagline }, token);
  }

  return tagline;
};

export const deleteCompany = (companyId) => {
  const token = localStorage.getItem('jwtToken');

  return fetch(`${companiesURL}/${companyId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  }).then(res => res.json());
};