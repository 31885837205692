export const baseURL = "https://venturify-core-api-qzrpojm45q-uc.a.run.app";

export const companiesURL = `${baseURL}/companies`;

export const tasksURL = `${baseURL}/tasks`;

export const usersURL = `${baseURL}/users`;

export const askURL = `${baseURL}/ask`;

export const launchPagesUrl = `${baseURL}/launchpages`;