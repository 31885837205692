import { launchPagesUrl } from "../urls";

// Function to create a new launch page
export const postLaunchPage = async (data) => {
  const token = localStorage.getItem("jwtToken");
  try {
    const response = await fetch(launchPagesUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Failed to create launch page');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Add user to waitlist
export const postToWaitlist = async (data) => {
  try {
    const response = await fetch(`${launchPagesUrl}/${data.slug}/waitlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Failed to update waitlist');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Increment page views count
export const updatePageViews = async (data) => {
  try {
    const response = await fetch(`${launchPagesUrl}/${data.slug}/page-views`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Failed to update page views count');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};